:root {
  [app-theme='light'] {
    --color-light-100-forever: #fdfdfd;
    --color-dark-100-forever: #252525;

    /* Couleur Light [Mode clair] */
    --color-light-100-transparent: #fdfdfd00;
    --color-light-100: #fdfdfd;
    --color-light-90: #f5f5f5;
    --color-light-80: #e5e5e5;
    --color-light-70: #d5d5d5;
    --color-light-60: #a6a6a6;
    --color-light-50: #8c8c8c;
    --color-light-40: #7a7a7a;
    --color-light-30: #626262;
    --color-light-20: #4b4b4b;
    --color-light-10: #3b3b3b;
    --color-light-3: #131313;

    /* Couleur Dark [Mode clair] */
    --color-dark-100: #252525;
    --color-dark-90: #3b3b3b;
    --color-dark-80: #515151;
    --color-dark-70: #666666;
    --color-dark-60: #7c7c7c;
    --color-dark-50: #929292;
    --color-dark-40: #a8a8a8;
    --color-dark-30: #bebebe;
    --color-dark-20: #d3d3d3;
    --color-dark-10: #e9e9e9;
    --color-dark-3: #f8f8f8;

    /* Couleur Primary [Mode clair] */
    --color-primary-100: #33426a; /* hsl(223, 34%, 31%) */
    --color-primary-90: #475579;
    --color-primary-80: #5c6888;
    --color-primary-70: #707b97;
    --color-primary-60: #858ea6;
    --color-primary-50: #99a1b5;
    --color-primary-40: #adb3c3;
    --color-primary-30: #c2c6d2;
    --color-primary-20: #d6d9e1;
    --color-primary-10: #ebecf0;
    --color-primary-5: #eff0f2;
    --color-primary-3: #f5f6f8;

    /* Couleur Secondary [Mode clair] */
    --color-secondary-100: #007a7a; /* hsl(177, 100%, 24%) */
    --color-secondary-90: #1e8b8b;
    --color-secondary-80: #339898;
    --color-secondary-70: #4aa6a6;
    --color-secondary-60: #61b5b5;
    --color-secondary-50: #77c3c3;
    --color-secondary-40: #8ed2d2;
    --color-secondary-30: #a5e0e0;
    --color-secondary-20: #bbefef;
    --color-secondary-10: #d2fdfd;
    --color-secondary-3: #f2fefe;

    /* Couleur Warning [Mode clair] */
    --color-warning-100: #ffbe07; /* hsl(40, 99%, 58%) */
    --color-warning-90: #ffc338;
    --color-warning-80: #ffc85c;
    --color-warning-70: #ffcd7d;
    --color-warning-60: #ffd39b;
    --color-warning-50: #ffd8b7;
    --color-warning-40: #ffddcf;
    --color-warning-30: #ffe3e5;
    --color-warning-20: #ffe8f0;
    --color-warning-10: #ffedf8;

    /* Couleur Danger [Mode clair] */
    --color-danger-100: #f44e4e; /* hsl(0, 61%, 60%) */
    --color-danger-90: #f66464;
    --color-danger-80: #f87979;
    --color-danger-70: #fa8f8f;
    --color-danger-60: #fca5a5;
    --color-danger-50: #febaba;
    --color-danger-40: #ffd0d0;
    --color-danger-30: #ffe6e6;
    --color-danger-20: #fff1f1;
    --color-danger-10: #fff8f8;

    /* Couleur Fancy [Mode clair] */
    --color-fancy-lime: #dff095; /* hsl(72, 79%, 75%) */
    --color-fancy-sky: #3b6ef7; /* hsl(217, 91%, 60%) */
    --color-fancy-pink: #f766d8; /* hsl(308, 93%, 63%) */

    /* Couleur Pastel [Mode clair] */
    --color-pastel-100: #ffe7d5; /* Warm Pastel Peach */
    --color-pastel-90: #ffcad4; /* Pastel Pink */
    --color-pastel-80: #cce5ff; /* Pastel Blue */
    --color-pastel-70: #d4f1c4; /* Pastel Green */
    --color-pastel-60: #f9e79f; /* Pastel Yellow */
    --color-pastel-50: #f2c6d2; /* Pastel Rose */
    --color-pastel-40: #d3c9ff; /* Pastel Lavender */
    --color-pastel-30: #fbf1c7; /* Pastel Apricot */
    --color-pastel-20: #e5f8f0; /* Pastel Mint */
    --color-pastel-10: #f3e8b7; /* Soft Pastel Cream */
    --color-pastel-3: #f5e3ff; /* Pastel Lilac */
  }

  [app-theme='dark'] {
    --color-light-100-forever: #fdfdfd;
    --color-dark-100-forever: #252525;

    /* Couleur Light [Mode sombre] */
    --color-light-100-transparent: #212e3300;
    --color-light-100: #212e33;
    --color-light-90: #374347;
    --color-light-80: #4d585c;
    --color-light-70: #646d70;
    --color-light-60: #7a8285;
    --color-light-50: #909799;
    --color-light-40: #a6abad;
    --color-light-30: #bcc0c2;
    --color-light-20: #d3d5d6;
    --color-light-10: #e9eaeb;
    --color-light-3: #f8f9f9;

    /* Couleur Dark [Mode sombre] */
    --color-dark-3: #28383e;
    --color-dark-10: #3e4c51;
    --color-dark-20: #536065;
    --color-dark-30: #697478;
    --color-dark-40: #7e888b;
    --color-dark-50: #949c9f;
    --color-dark-60: #a9afb2;
    --color-dark-70: #bfc3c5;
    --color-dark-80: #d4d7d8;
    --color-dark-90: #eaebec;
    --color-dark-100: #f9f9f9;

    /* Couleur Primary [Mode sombre] */
    --color-primary-100: #dedff9; /* hsl(233, 88%, 90%) */
    --color-primary-90: #e1e2fa;
    --color-primary-80: #e5e6fb;
    --color-primary-70: #e8e9fb;
    --color-primary-60: #ecedfc;
    --color-primary-50: #eff0fd;
    --color-primary-40: #f2f3fd;
    --color-primary-30: #f6f6fe;
    --color-primary-20: #f9f9fe;
    --color-primary-10: #fcfcff;
    --color-primary-3: #fefeff;

    /* Couleur Secondary [Mode sombre] */
    --color-secondary-100: #009999; /* hsl(178, 100%, 30%) */
    --color-secondary-90: #1ea6a6;
    --color-secondary-80: #33b2b2;
    --color-secondary-70: #4abfbf;
    --color-secondary-60: #61cccc;
    --color-secondary-50: #77d8d8;
    --color-secondary-40: #8ee5e5;
    --color-secondary-30: #a5f2f2;
    --color-secondary-20: #bbffff;
    --color-secondary-10: #d2ffff;
    --color-secondary-3: #f2ffff;

    /* Couleur Warning [Mode sombre] */
    --color-warning-100: #ffbe07; /* hsl(40, 99%, 58%) */
    --color-warning-90: #ffc338;
    --color-warning-80: #ffc85c;
    --color-warning-70: #ffcd7d;
    --color-warning-60: #ffd39b;
    --color-warning-50: #ffd8b7;
    --color-warning-40: #ffddcf;
    --color-warning-30: #ffe3e5;
    --color-warning-20: #ffe8f0;
    --color-warning-10: #ffedf8;

    /* Couleur Danger [Mode sombre] */
    --color-danger-100: #f44e4e; /* hsl(0, 61%, 60%) */
    --color-danger-90: #f66464;
    --color-danger-80: #f87979;
    --color-danger-70: #fa8f8f;
    --color-danger-60: #fca5a5;
    --color-danger-50: #febaba;
    --color-danger-40: #ffd0d0;
    --color-danger-30: #ffe6e6;
    --color-danger-20: #fff1f1;
    --color-danger-10: #fff8f8;

    /* Couleur Fancy [Mode sombre] */
    --color-fancy-lime: #615e9b; /* hsl(195, 23%, 49%) */
    --color-fancy-sky: #3e77b2; /* hsl(207, 50%, 49%) */
    --color-fancy-pink: #f766d8; /* hsl(308, 93%, 63%) */

    /* Couleur Pastel [Mode sombre] */
    --color-pastel-100: #2a292f; /* Dark Pastel Black */
    --color-pastel-90: #4d3e4e; /* Dark Pastel Plum */
    --color-pastel-80: #3d4e5b; /* Dark Pastel Slate Blue */
    --color-pastel-70: #4b5e52; /* Dark Pastel Sage Green */
    --color-pastel-60: #b99c3a; /* Dark Pastel Olive */
    --color-pastel-50: #f1c3d3; /* Dark Pastel Blush */
    --color-pastel-40: #7266c6; /* Dark Pastel Indigo */
    --color-pastel-30: #dfd5b3; /* Dark Pastel Sand */
    --color-pastel-20: #3b4e41; /* Dark Pastel Forest Green */
    --color-pastel-10: #6b570c; /* Dark Pastel Mustard */
    --color-pastel-3: #564186; /* Dark Pastel Amethyst */
  }
}
