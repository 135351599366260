@import '/src/app/shared/styles/mixins.scss';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@import 'src/themes/custom/colors.scss';
@import 'src/themes/custom/misc.css';
@import 'src/themes/custom/fonts.css';
@import 'src/themes/custom/text.css';

:root {
  --sidenav-width: 250px;
  --sidenav-collapsed-width: 0px;
  --content-max-width: calc(100%);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  --pf-sm: 576px;
  --pf-md: 768px;
  --pf-lg: 992px;
  --pf-xl: 1200px;
}

html {
  box-sizing: border-box;
  font-size: clamp(12px, 1vw, 14px);
}

body {
  background-color: var(--color-light-100);
  margin: 0;
  padding: 0;
}

body,
body .p-component,
.p-dropdown,
.p-inputtext {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

main {
  height: calc(100dvh - 71px);
  overflow: scroll;
  scrollbar-width: none;
}

/*This fix modify all buttons's poisition and can possibly break something*/
.p-button {
  position: static;
}

h1.display-h1 {
  @include ice-display-h1;
}

h2.display-h2 {
  @include ice-display-h2;
}

h3.display-h3 {
  @include ice-display-h3;
}

h4.display-h4 {
  @include ice-display-h4;
}

h5.display-h5 {
  @include ice-display-h5;
}

h6.display-h6 {
  @include ice-display-h6;
}

h1.heading-h1 {
  @include ice-heading-h1;
}

h2.heading-h2 {
  @include ice-heading-h2;
}

h3.heading-h3 {
  @include ice-heading-h3;
}

h4.heading-h4 {
  @include ice-heading-h4;
}

h5.heading-h5 {
  @include ice-heading-h5;
}

h6.heading-h6 {
  @include ice-heading-h6;
}

.text-2xl {
  @include ice-text-2xl;
}

.text-xl {
  @include ice-text-xl;
}

.text-lg {
  @include ice-text-lg;
}

.text-base {
  @include ice-text-base;
}

.text-sm {
  @include ice-text-sm;
}

.text-xs {
  @include ice-text-xs;
}

.text-bold-2xl {
  @include ice-text-bold-2xl;
}

.text-bold-xl {
  @include ice-text-bold-xl;
}

.text-bold-lg {
  @include ice-text-bold-lg;
}

.text-bold-base {
  @include ice-text-bold-base;
}

.text-bold-sm {
  @include ice-text-bold-sm;
}

.text-bold-xs {
  @include ice-text-bold-xs;
}

.ui-text-2xl {
  @include ice-ui-text-2xl;
}

.ui-text-xl {
  @include ice-ui-text-xl;
}

.ui-text-lg {
  @include ice-ui-text-lg;
}

.ui-text-base {
  @include ice-ui-text-base;
}

.ui-text-sm {
  @include ice-ui-text-sm;
}

.ui-text-xs {
  @include ice-ui-text-xs;
}

h1 {
  @include ice-heading-1;
}

h2 {
  @include ice-heading-2;
}

h3 {
  @include ice-heading-3;
}

h4 {
  @include ice-heading-4;
}

.ice-subtitle-1 {
  @include ice-subtitle-1;
}

.ice-subtitle-2 {
  @include ice-subtitle-2;
}

.ice-body-1 {
  @include ice-body-1;
}

.ice-body-2 {
  @include ice-body-2;
}

.ice-caption {
  @include ice-caption;
}

.ice-overline {
  @include ice-overline;
}

.ice-vertical-rotate {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(-180deg);
  font-weight: bold !important;
  text-transform: uppercase;
  text-align: -moz-right;
}

.w-1-5rem {
  width: 1.5rem;
}

.h-1-5rem {
  height: 1.5rem;
}

.ml-1-5 {
  margin-left: 0.35rem !important;
}

.gap-2-5 {
  gap: 0.625rem;
}

.m-2-5 {
  margin: 0.625rem;
}

.ml-2-5 {
  margin-left: 0.625rem;
}

.mb-3-5 {
  margin-bottom: 1.25rem;
}

.mt-3-5 {
  margin-top: 1.25rem;
}

.ml-3-5 {
  margin-left: 1.25rem;
}

.mr-3-5 {
  margin-right: 1.25rem;
}

.gap-3-5 {
  gap: 1.25rem;
}

.mb-4-8 {
  margin-bottom: 1.875rem;
}

.mt-4-8 {
  margin-top: 1.875rem;
}

.ml-4-8 {
  margin-left: 1.875rem;
}

.mr-4-8 {
  margin-right: 1.875rem;
}

.gap-4-8 {
  gap: 1.875rem;
}

.mb-7-75 {
  margin-bottom: 3.75rem;
}

.mt-7-75 {
  margin-top: 3.75rem;
}

.ml-7-75 {
  margin-left: 3.75rem;
}

.mr-7-75 {
  margin-right: 3.75rem;
}

.gap-7-75 {
  gap: 3.75rem;
}

.px-2-5 {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.py-2-5 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3-5 {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.px-3-75 {
  padding-right: 1.43rem;
  padding-left: 1.43rem;
}

.pb-ice {
  padding-bottom: 1.25rem;
}

.border-round-2lg {
  border-radius: 0.625rem;
}

.border-round-2-5xl {
  border-radius: 1.25rem;
}

.p-password {
  width: 100%;
  & > input {
    width: 100%;
  }
}

.text-3-5xl {
  font-size: 1.875rem;
}

.w-19-25rem {
  width: 19.25rem;
}

//Phone iso full width
#phoneIso {
  width: 100%;
}
#ice-dropdown-custom {
  width: 100%;
}

@media (min-width: 399.98px) {
  .xs\:col-1 {
    flex: 0 0 8.33%;
    min-width: 8.33%;
  }
  .xs\:col-2 {
    flex: 0 0 16.67%;
    min-width: 16.67%;
  }
  .xs\:col-3 {
    flex: 0 0 25%;
    min-width: 25%;
  }
  .xs\:col-4 {
    flex: 0 0 33.33%;
    min-width: 33.33%;
  }
  .xs\:col-5 {
    flex: 0 0 41.67%;
    min-width: 41.67%;
  }
  .xs\:col-6 {
    flex: 0 0 50%;
    min-width: 50%;
  }
  .xs\:col-7 {
    flex: 0 0 58.33%;
    min-width: 58.33%;
  }
  .xs\:col-8 {
    flex: 0 0 66.67%;
    min-width: 66.67%;
  }
  .xs\:col-9 {
    flex: 0 0 75%;
    min-width: 75%;
  }
  .xs\:col-10 {
    flex: 0 0 83.33%;
    min-width: 83.33%;
  }
  .xs\:col-11 {
    flex: 0 0 91.67%;
    min-width: 91.67%;
  }
  .xs\:col-12 {
    flex: 0 0 100%;
    min-width: 100%;
  }
  // Ajoutez d'autres classes personnalisées pour xs ici si nécessaire

  .p-picklist-list::-webkit-scrollbar {
    background: transparent !important; /* Make scrollbar transparent */
    width: 6px; /* Set the width of the scrollbar */
  }

  .p-picklist-list::-webkit-scrollbar-thumb {
    background: transparent; /* Make scrollbar thumb transparent */
    border-radius: 10px;
  }

  /* The scrollbar becomes opaque when hovering */
  .p-picklist-list:hover::-webkit-scrollbar-thumb {
    background: red; /* Change to any color you want */
  }

  p-confirm-popup::after,
  .p-confirm-popup::before {
    display: none;
  }
}

.p-autocomplete {
  display: flex !important;
}

.label-required {
  @include ice-label(true);
}

.label {
  @include ice-label(false);
}
@layer reset, primeng;

.loading-page {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .ice-title {
    //animation: rotation 2s infinite linear;
  }
}

.transition-margin {
  transition: margin 150ms ease-in-out;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-2 {
  flex: 2;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.word-break-all {
  word-break: break-all;
}

.letter-spacing-1 {
  letter-spacing: 0.045rem;
}

.family-monserrat {
  font-family: Montserrat, monospace;
}

.family-geist {
  font-family: Geist, monospace;
}

.line-height-1-5rem {
  line-height: 1.5rem;
}

.line-height-1-75rem {
  line-height: 1.75rem;
}

.line-height-2rem {
  line-height: 2rem;
}

.text-align-right {
  text-align: right;
}

.text-nowrap {
  text-wrap: nowrap;
}

.floating-handle-left {
  color: var(--color-light-100);
  background-color: var(--color-dark-100);
  border-radius: 0 0.25rem 0.25rem 0.25rem;
  display: flex;
  width: 2.3125rem;
  height: 0.99694rem;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  font-family: Montserrat, monospace;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.floating-handle-r {
  color: var(--color-light-100);
  background-color: var(--color-dark-100);
  border-radius: 0 0.25rem 0.25rem 0.25rem;
  display: flex;
  width: 2.3125rem;
  height: 0.99694rem;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  font-family: Montserrat, monospace;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

/* Visual hint at the top of the element to indicate that there is more content available by scrolling.
   It uses a pseudo-element with a gradient background to achieve this effect. This is particularly useful for improving user experience
   by subtly suggesting that the content continues beyond the visible area. */
.ice-blur-border {
  position: relative;
  background-color: var(--color-light-100);
}

.ice-blur-border::before {
  content: '';
  position: absolute;
  top: -6rem;
  width: 100%;
  height: 6rem;
  background: linear-gradient(rgba(255, 255, 255, 0), var(--color-light-100));
}

/* Visual hint at the bottom of the element to indicate that there is more content available by scrolling.
   It uses a pseudo-element with a gradient background to achieve this effect, suggesting that the content continues beyond the visible area. */
.ice-blur-border-bottom {
  position: relative;
  background-color: var(--color-light-100);
}

.ice-blur-border-bottom::after {
  content: '';
  position: absolute;
  bottom: -6rem; /* Positioned at the bottom */
  width: 100%;
  height: 6rem;
  background: linear-gradient(
    var(--color-light-100),
    rgba(255, 255, 255, 0)
  ); /* Gradient inversé */
}

.ice-blur-border-grey {
  position: relative;
  background-color: var(--color-primary-5);
}

.ice-blur-border-grey::before {
  content: '';
  position: absolute;
  top: -3.5rem;
  width: 100%;
  height: 3.5rem;
  background: linear-gradient(rgba(255, 255, 255, 0), var(--color-primary-5));
}

.ice-bottom-padding {
  padding-bottom: 10rem;
}

.max-w-46-rem {
  max-width: 46rem !important;
}

.shepherd-element {
  background-color: var(--color-light-100) !important;
  border-radius: 1rem !important;
  box-shadow: none !important;
  border: solid 0.1rem var(--color-dark-20) !important;
}

.shepherd-header {
  background-color: var(--color-light-100) !important;
  padding: 2rem !important;
  padding-bottom: 0 !important;
  border-radius: 1rem !important;
}

.shepherd-text {
  padding: 2rem !important;
  font-family: Montserrat, monospace !important;
  font-size: 1.1rem !important;
  color: var(--color-dark-70) !important;
}

.shepherd-arrow::before {
  background: var(--color-light-100) !important;
  border-left: solid 0.1rem var(--color-dark-20) !important;
  border-bottom: solid 0.1rem var(--color-dark-20) !important;
}

.shepherd-button {
  background: var(--color-fancy-pink) !important;
  color: var(--color-light-100) !important;
}

.shepherd-modal-overlay-container {
  background-color: var(--color-light-100) !important; /* Couleur du masque */
  transition: background-color 0.9s ease !important; /* Animation du masque */
}

.shepherd-target {
  box-shadow: 0 0 5rem 5rem var(--color-light-100-forever) !important; /* Ajoute un effet de halo autour de la cible */
  border-radius: 1rem !important; /* Ajoute des coins arrondis */
}

@media screen and (min-width: 768px) {
  .md\:gap-3-5 {
    gap: 1.25rem !important;
  }
}

@media screen and (min-height: 814px) {
  .lgh\:gap-6 {
    gap: 3rem !important;
  }

  .lgh\:pt-4 {
    margin: 1.5rem !important;
  }
}
