@import 'src/themes/custom/colors.scss';

.hover\:ice-card:hover {
  background-color: var(--color-primary-3);
  /*transform: scale(1.01);*/
}

.cursor-stop {
  cursor: not-allowed !important;
}

.bg-blur-top {
  background: linear-gradient(
    to bottom,
    var(--color-light-100),
    var(--color-light-100-transparent)
  );
}

.top-10 {
  top: 10% !important;
}
