@import 'primeflex/primeflex.scss';

// Display Styles
@mixin ice-display-h1 {
  font-size: 5.71rem !important;
  line-height: 5.71rem !important;
  font-family: 'Geist';
  @include styleclass('font-medium');
}

@mixin ice-display-h2 {
  font-size: 4.29rem !important;
  line-height: 4.29rem !important;
  font-family: 'Geist';
  @include styleclass('font-medium');
}

@mixin ice-display-h3 {
  font-size: 3.43rem !important;
  line-height: 3.43rem !important;
  font-family: 'Geist';
  @include styleclass('font-medium');
}

@mixin ice-display-h4 {
  font-size: 2.57rem !important;
  line-height: 2.86rem !important;
  font-family: 'Geist';
  @include styleclass('font-medium');
}

@mixin ice-display-h5 {
  font-size: 1.71rem !important;
  line-height: 2rem !important;
  font-family: 'Geist';
  @include styleclass('font-medium');
}

@mixin ice-display-h6 {
  font-size: 1.43rem !important;
  line-height: 2rem !important;
  font-family: 'Geist';
  @include styleclass('font-medium');
}

// Heading Styles
@mixin ice-heading-h1 {
  font-size: 2.14rem !important;
  line-height: 2.57rem !important;
  @include styleclass('font-medium');
}

@mixin ice-heading-h2 {
  font-size: 1.71rem !important;
  line-height: 2.29rem !important;
  @include styleclass('font-medium');
}

@mixin ice-heading-h3 {
  font-size: 1.43rem !important;
  line-height: 2rem !important;
  @include styleclass('font-medium');
}

@mixin ice-heading-h4 {
  font-size: 1.29rem !important;
  line-height: 2rem !important;
  @include styleclass('font-medium');
}

@mixin ice-heading-h5 {
  font-size: 1.14rem !important;
  line-height: 1.71rem !important;
  @include styleclass('font-medium');
}

@mixin ice-heading-h6 {
  font-size: 1rem !important;
  line-height: 1.43rem !important;
  @include styleclass('font-medium');
}

// Text Styles
@mixin ice-text-2xl {
  font-size: 1.71rem !important;
  line-height: 2.29rem !important;
  @include styleclass('font-regular');
}

@mixin ice-text-xl {
  font-size: 1.43rem !important;
  line-height: 2rem !important;
  @include styleclass('font-regular');
}

@mixin ice-text-lg {
  font-size: 1.29rem !important;
  line-height: 2rem !important;
  @include styleclass('font-regular');
}

@mixin ice-text-base {
  font-size: 1.14rem !important;
  line-height: 1.71rem !important;
  @include styleclass('font-regular');
}

@mixin ice-text-sm {
  font-size: 1rem !important;
  line-height: 1.43rem !important;
  @include styleclass('font-regular');
}

@mixin ice-text-xs {
  font-size: 0.86rem !important;
  line-height: 1.14rem !important;
  @include styleclass('font-regular');
}

// Text Bold Styles
@mixin ice-text-bold-2xl {
  font-size: 2.29rem !important;
  line-height: 2.29rem !important;
  color: var(--color-dark-80);
  font-weight: 550 !important;
}

@mixin ice-text-bold-xl {
  font-size: 1.43rem !important;
  line-height: 2rem !important;
  color: var(--color-dark-80);
  font-weight: 550 !important;
}

@mixin ice-text-bold-lg {
  font-size: 1.29rem !important;
  line-height: 2rem !important;
  color: var(--color-dark-80);
  font-weight: 550 !important;
}

@mixin ice-text-bold-base {
  font-size: 1.14rem !important;
  line-height: 1.71rem !important;
  color: var(--color-dark-80);
  font-weight: 550 !important;
}

@mixin ice-text-bold-sm {
  font-size: 1rem !important;
  line-height: 1.43rem !important;
  color: var(--color-dark-80);
  font-weight: 550 !important;
}

@mixin ice-text-bold-xs {
  font-size: 0.86rem !important;
  line-height: 1.14rem !important;
  font-weight: 550 !important;
}

// UI Text Styles
@mixin ice-ui-text-2xl {
  font-size: 1.71rem !important;
  line-height: 2.29rem !important;
  @include styleclass('font-medium');
}

@mixin ice-ui-text-xl {
  font-size: 1.43rem !important;
  line-height: 2rem !important;
  @include styleclass('font-medium');
}

@mixin ice-ui-text-lg {
  font-size: 1.29rem !important;
  line-height: 2rem !important;
  @include styleclass('font-medium');
}

@mixin ice-ui-text-base {
  font-size: 1.14rem !important;
  line-height: 1.71rem !important;
  @include styleclass('font-medium');
}

@mixin ice-ui-text-sm {
  font-size: 1rem !important;
  line-height: 1.43rem !important;
  @include styleclass('font-medium');
}

@mixin ice-ui-text-xs {
  font-size: 0.86rem !important;
  line-height: 1.14rem !important;
  @include styleclass('font-medium');
}

@mixin ice-heading-1 {
  @include styleclass('text-3xl, font-medium');
}

@mixin ice-heading-2 {
  @include styleclass('text-2xl, font-medium mt-0');
}

@mixin ice-heading-3 {
  @include styleclass('text-xl, font-regular');
}

@mixin ice-heading-4 {
  @include styleclass('text-lg, font-regular');
}

@mixin ice-subtitle-1 {
  @include styleclass('text-base font-normal text-400 text-gray-900');
  letter-spacing: 0.15px;
}

@mixin ice-subtitle-2 {
  @include styleclass('text-sm font-medium text-400 text-gray-600');
  letter-spacing: 0.1px;
}

@mixin ice-body-1 {
  @include styleclass('text-base text-400 text-gray-900 line-height-3');
  letter-spacing: 0.25px;
}

@mixin ice-body-2 {
  @include styleclass('text-sm text-400 text-gray-600');
  letter-spacing: 0.25px;
}

@mixin ice-caption {
  @include styleclass('text-xs text-400 text-gray-700');
  letter-spacing: 0.4px;
}

@mixin ice-overline {
  @include styleclass('text-xs text-500 text-gray-700 text-uppercase');
  letter-spacing: 1.5px;
}

@mixin ice-label($isRequired: false) {
  font-weight: bold;
  font-size: 0.875rem;

  @if $isRequired {
    &::after {
      content: '*';
      @include styleclass('text-red-600');
      margin-left: 0.5rem;
    }
  }
}
